import React, { Component } from 'react';
import 'index.css';
import 'components/Footer.scss';

class Footer extends Component {
    render() {
        let billet={url:'/'};
        if (this.props.modele.billets.length>0)
            billet=this.props.modele.billets[0];
        return <div className="footer">
            <div className="background"></div>
            <div className="content">
                <div className="row">
                    <div className="plan col-xs-120 col-sm-40">
                        <ul className="footer-liste">
                            {this.props.modele.menu.map((item,i)=>{
                                return <li key={i}><span onClick={()=>this.props.goTo(item.url)} dangerouslySetInnerHTML={{__html:item.titre}}></span></li>
                            })}
                            <li><span onClick={()=>this.props.goTo(billet.url)}>Blog</span></li>
                        </ul>
                    </div>
                    <div className="plan col-xs-120 col-sm-40">
                        <ul className="footer-liste">
                        {this.props.modele.groupes.map((groupe,i)=>{
                            return (
                                <li key={i}><span onClick={()=>this.props.goTo(groupe.url)}>{groupe.url.replace('https://','').replace('/','')}</span></li>
                            );
                        })}
                        </ul>
                    </div>
                    <div className="plan col-xs-120 col-sm-40">
                        <ul className="footer-liste">
                            <li><span onClick={()=>this.props.goTo('/mentions_legales/')}>Mentions légales</span></li>
                            <li><a href={this.props.modele.urlFb} target="_blank" rel="noopener noreferrer"><div className="fb"></div></a>
</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>;
    }
}
export default Footer;
