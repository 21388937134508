import React, { Component } from 'react';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import 'components/Menu.scss';

class Menu extends Component {
    constructor(props){
        super(props);
        this.state={
            anchorEl:null,
        }
        this.handleMenuOpen=this.handleMenuOpen.bind(this);
        this.handleMenuClose=this.handleMenuClose.bind(this);
    }
    handleMenuOpen(e){
        this.setState({anchorEl:e.target});
    }
    handleMenuClose(){
        this.setState({anchorEl:null});
    }
    render() {
        let menu='';
        if(this.props.menu.length>0) menu=(
            <div className="menu">
                <h3 className="logo titre" onClick={this.props.goTop}>Pirèna immatèria</h3>
                <div className="logo soustitre">Votz - Creacion - Ligam</div>
                <table className="boutons">
                    <tbody>
                    <tr>
                    {this.props.menu.map((s,i)=>{
                        let menuClass='';
                        if (this.props.currentRoute.url.indexOf(s.url)===0) menuClass+=' active';
                        let gutter=null;
                        if (i>0) gutter=<td className='gutter' key={'g'+i}></td>;
                        return [
                            gutter,
                            <td className={menuClass} key={s.url}>
                                {s.url==='/collectages/' ? <a target="_blank" rel="noopener noreferrer" href="https://collectages.pirena-immateria.org" dangerouslySetInnerHTML={{__html:s.titre}}></a> : <span className="lien" onClick={()=>this.props.goTo(s.url)} dangerouslySetInnerHTML={{__html:s.titre}}></span>}
                            </td>
                        ];
                    })}
                    </tr>
                    </tbody>
                </table>
                <div className="burger" onClick={this.handleMenuOpen}>
                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
                </div>
                 <MuiMenu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                >
                {this.props.menu.map((s,i)=>{
                    return <MenuItem className='xs-menu-item' key={s.url} onClick={()=>{this.props.goTo(s.url);this.handleMenuClose();}}><span dangerouslySetInnerHTML={{__html:s.titre}}></span></MenuItem>;
                })}
                </MuiMenu>
            </div>
        );
        return menu;
    }
}
export default Menu;
