import React, { Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import 'index.css';
import 'components/Agenda.scss';
import Html from 'components/Html.js';
import TuileEvenement from 'components/TuileEvenement.js';

class Agenda extends Component {
    constructor(props){
        super(props);
        this.state={
            filter:'',
            pageArchive:0,
        }
        this.filter=this.filter.bind(this);
        this.archivesPrev=this.archivesPrev.bind(this);
        this.archivesNext=this.archivesNext.bind(this);
    }
    filter(g){
        this.setState({filter:g});
    }
    archivesPrev(g){
        let p=this.state.pageArchive;
        this.setState({pageArchive:Math.max(0,p-1)});
    }
    archivesNext(g){
        let p=this.state.pageArchive;
        let d=moment().format('YYYY/MM/DD');
        let archives=_.filter(this.props.modele.evts,(e)=>e.date_fin<d);
        this.setState({pageArchive:Math.min(archives.length/4,p+1)});
    }
    render() {
        let data=this.props.data;
        let d=moment().format('YYYY/MM/DD');
        let groupes=[];
        (this.props.modele.evts).forEach((e,i)=>{
            if (e.groupe_titre && groupes.indexOf(e.groupe_titre)===-1)
                groupes.push(e.groupe_titre);
            return null;
        });
        let evts=[];
        if (!this.state.filter) evts=this.props.modele.evts;
        else evts=_.filter(this.props.modele.evts,{groupe_titre:this.state.filter});
        let selection=_.filter(evts,(e)=>e.date_fin>d);
        let archives=_.filter(evts,(e)=>e.date_fin<=d);
        let archivesEvts='';
        let prev, next;
        if (archives.length>0) {
            if (this.state.pageArchive>0) prev=<span onClick={this.archivesPrev} className="pointer prev retour-ctrl">
                Suivant
            </span>;
            else prev=<span className="prev retour-ctrl">
                Suivant
            </span>;
            if (this.state.pageArchive<archives.length/4-1) next=<span onClick={this.archivesNext} className="pointer next retour-ctrl">
                Précédent
            </span>;
            else next=<span className="next retour-ctrl">
                Précédent
            </span>;
            archivesEvts=<div>
                <div className="col-xs-120">
                    <h3 className="titre titre-agenda">Événements passés</h3>
                    <div className="retour-btn">
                    {next}
                    {prev}
                    </div>
                </div>
                {_.filter(archives,(o,i)=> i> (archives.length-1)-(this.state.pageArchive+1)*4 && i<= (archives.length-1)-(this.state.pageArchive)*4 ).map((e,i)=>{
                    let res=[
                        <div key={e.id_eve} className="col-xs-60 col-sm-30">
                            <TuileEvenement
                            evenement={e}
                            goTo={this.props.goTo}
                            />
                        </div>
                    ];
                    if (i%4===3) res.push(<div key={e.id_eve+'-1'} className="clearfix inter hidden-xs"></div>);
                    if (i%2===1) res.push(<div key={e.id_eve+'-3'} className="clearfix inter visible-xs"></div>);
                    return res;
                })}
                <div className="clearfix"></div>
            </div>;
        }
        let toutClass= this.state.filter ? 'button' : 'button on';
        let agendaFilter='';
        if (groupes.length>0) agendaFilter=<div>
            <p>
            {groupes.map((g,i)=>{
                let btnClass='button';
                if (g===this.state.filter) btnClass+=' on';
                return <span key={i} onClick={()=>this.filter(g)} className={btnClass}>{g}</span>;
            })}
            <span onClick={()=>this.filter('')} className={toutClass}>tout</span>
            </p>
        </div>;

        return (
            <div>
                <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
                <div className="paragraphe">
                    <Html html={data.texte} navigate={this.props.goTo}/>
                </div>
                {agendaFilter}
                <div className="row liste">
                    {selection.map((e,i)=>{
                        let res=[
                            <div key={e.id_eve} className="col-xs-60 col-sm-30 fadein">
                                <TuileEvenement
                                evenement={e}
                                goTo={this.props.goTo}
                                />
                            </div>
                        ];
                        if (i%4===3) res.push(<div key={e.id_eve+'-1'} className="clearfix inter hidden-xs"></div>);
                        if (i%2===1) res.push(<div key={e.id_eve+'-3'} className="clearfix inter visible-xs"></div>);
                        return res;
                    })}
                    <div className="clearfix"></div>
                    {archivesEvts}
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}
export default Agenda;
