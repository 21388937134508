import React, { Component } from 'react';
import 'index.css';
import 'components/Sons.scss';
import PlayerAudio from 'components/PlayerAudio';

class Sons extends Component {
    render() {
        return (
            <div className="liste">
                {this.props.sons.map((s,i)=>{
                    return <PlayerAudio
                    key={s.url}
                    playlist={this.props.playlist}
                    modele={this.props.modele}
                    methods={this.props.methods}
                    sound={s}
                    />
                })}
            </div>
        );
    }
}
export default Sons;
