import React, { Component } from 'react';
import _ from 'lodash';
import 'assets/bootstrap.min.custom.css';
import './App.scss';
import Router  from 'components/Router';
import { createBrowserHistory } from 'history';
//import Qs from 'qs';
import axios from 'axios';
import enterView from 'enter-view';
import FontFaceObserver from 'fontfaceobserver';
import Utils from 'utils/Utils';
import ED from 'utils/EventDispatcher';
import Playlist from 'utils/Playlist';

//routes
import Accueil from 'components/Accueil';
import ActionCulturelleRub from 'components/ActionCulturelleRub';
import ActionCulturelle from 'components/ActionCulturelle';
import ActionCulturelleAction from 'components/ActionCulturelleAction';
import Polyphonie from 'components/Polyphonie';
import Evenement from 'components/Evenement';
import Agenda from 'components/Agenda';
import Boutique from 'components/Boutique';
import Collectages from 'components/Collectages';
import Contacts from 'components/Contacts';
import Billet from 'components/Billet';
import Mentions from 'components/Mentions';

import Fond from 'components/Fond';
import Slider from 'components/Slider';
import Menu from 'components/Menu';
import PlayerAudio from 'components/PlayerAudio';

class App extends Component {
    constructor(props){
        super(props);
        this.state={
            templates:[
                {id:'accueil',component: Accueil },
                {id:'acRub',component: ActionCulturelleRub },
                {id:'ac',component: ActionCulturelle },
                {id:'acAction',component: ActionCulturelleAction },
                {id:'polyphonie',component: Polyphonie },
                {id:'evenement',component: Evenement },
                {id:'agenda',component: Agenda },
                {id:'boutique',component: Boutique },
                {id:'collectages',component: Collectages },
                {id:'contacts',component: Contacts },
                {id:'billet',component: Billet },
                {id:'mentions',component: Mentions },
            ],
            routes:[],
            modele:{
                menu:[],
                sons:[],
                evts:[],
                billets:[],
                groupes:[],
                selectedPlayer:null,
                soundFilter:'',
            },
            slider:[],
            screenSize:'xs',
            mainRatio:1,
            wait:0,
            currentRoute:{url:'/'},
            fond:{
                widthClass:'xs',
                ratio:1,
                dy:0,
                dx:0,
            }
        }
        this.methods={
            setSoundFilter:this.setSoundFilter.bind(this),
            playNextSound:this.playNextSound.bind(this),
        }
        this.started=false;
        this.base='';
        this.history = createBrowserHistory({basename:this.base});
        this.resize=this.resize.bind(this);
        this.goTo=this.goTo.bind(this);
        this.goContenu=this.goContenu.bind(this);
        this.handleRouteHasChanged=this.handleRouteHasChanged.bind(this);
        this.handleSliderLoaded=this.handleSliderLoaded.bind(this);
        this.handleSoundLoaded=this.handleSoundLoaded.bind(this);
        this.handleSoundPlay=this.handleSoundPlay.bind(this);
        this.ed=new ED();
        this.playlist=new Playlist(this.ed);
        //refs
        this.mainRef=React.createRef();
        this.mainSpacerRef=React.createRef();
        this.fondRef=React.createRef();
    }
    setSoundFilter(g){
        let modele=this.state.modele;
        modele.soundFilter=g;
        this.setState({modele:modele});
    }
    playNextSound(){
        let selection=this.state.modele.sons;
        if (this.state.modele.soundFilter!=='') selection=_.filter(this.state.modele.sons,{groupe:this.state.modele.soundFilter});
        let idx=_.findIndex(selection,{uuid:this.state.modele.selectedPlayer.uuid});
        if (idx>=0 && idx<selection.length-1) this.playlist.play(selection[idx+1].url);
        else this.playlist.play(selection[0].url);
    }
    handleSoundPlay(event,data){
        //console.log(event,data);
        let modele=this.state.modele;
        modele.selectedPlayer=data;
        this.setState({modele:modele});
    }
    handleSoundLoaded(event,data){
        //console.log(event,data);
        let modele=this.state.modele;
        modele.sons.push(data);
        this.setState({modele:modele});
    }
    componentDidMount() {
        this.ed.addEventListener('loadedmetadata',this.handleSoundLoaded);
        this.ed.addEventListener('play',this.handleSoundPlay);
        this.ed.addEventListener('ended',this.methods.playNextSound);
        this.enterview1=enterView({
        	selector: '.header',
        	enter: (el)=>null,
        	exit: (el)=>null,
        	progress: (el,p)=>{
                if (p>0.2) document.body.classList.add('scrolled');
        		else document.body.classList.remove('scrolled');
            },
        	offset: 1,
        });
        this.enterview2=enterView({
        	selector: '.main',
        	enter: function(el) {
                el.classList.add('entered');
        	},
        	exit: function(el) {
        		el.classList.remove('entered');
        	},
        	progress: function(el, progress) {
        	    //console.log(progress)
        	},
        	offset: 1,
        });
        axios({
            method: 'get',
            url: 'https://www.pirena-immateria.org/spip.php?page=api',
            withCredentials:true,
        }).then(res=> {
            let { billets,menu,groupes,sons,mainUrl,urlFb,routes,slider }=res.data;
            billets.sort(Utils.dateCompareBlog);
            this.sons=sons;
            Utils.shuffle(this.sons);
            urlFb=urlFb || 'https://www.facebook.com/';
            this.setState({
                routes,
                slider,
                modele:{...this.state.modele,billets,menu,groupes,mainUrl,urlFb},
                wait:this.state.wait+1
            });
            this.sons.map((s)=>
                this.playlist.addSound(s)
            );
            return null;
        });
        axios({
            method: 'get',
            url: 'https://www.pirena-immateria.org/spip.php?page=evts',
            withCredentials:true,
        }).then(res=> {
            let {evts,routes}=res.data;
            evts.sort(Utils.dateCompare);
            this.setState({
                routes:[...this.state.routes,...routes],
                modele:{...this.state.modele,evts},
            });
            return null;
        });
        let font1 = new FontFaceObserver('GillSans-LightItalic');
        font1.load().then(()=>this.setState({wait:this.state.wait+1}), ()=>this.setState({wait:this.state.wait+1},()=>console.log('Font is not available')) );
        let font2 = new FontFaceObserver('GillSans');
        font2.load().then(()=>this.setState({wait:this.state.wait+1}), ()=>this.setState({wait:this.state.wait+1},()=>console.log('Font is not available')) );
        let font3 = new FontFaceObserver('Syncopate-Fab');
        font3.load().then(()=>this.setState({wait:this.state.wait+1}), ()=>this.setState({wait:this.state.wait+1},()=>console.log('Font is not available')) );
        let font4 = new FontFaceObserver('OpenSans-Regular');
        font4.load().then(()=>this.setState({wait:this.state.wait+1}), ()=>this.setState({wait:this.state.wait+1},()=>console.log('Font is not available')) );
        window.addEventListener('resize',this.resize);
        this.resize();
    }
    componentWillUnmount(){
        this.ed.removeEventListener('loadedmetadata',this.handleSoundLoaded);
        this.ed.removeEventListener('play',this.handleSoundPlay);
        this.ed.removeEventListener('ended',this.playNextSound);
        window.removeEventListener('resize',this.resize);
    }
    handleSliderLoaded(){
        this.setState({wait:this.state.wait+1});
    }
    componentDidUpdate(){
        if (!this.started && this.state.wait>5){
            let style = document.createElement("style");
            style.appendChild(document.createTextNode(""));
            document.head.appendChild(style);
            let sheet=style.sheet;
            sheet.insertRule('html {scroll-behavior: smooth;}', 0);
            this.started=true;
        }
    }
    handleRouteHasChanged(route){
        if (route.go_top) this.goTop();
        else this.goContenu();
        this.setState({currentRoute:route});
    }
    goTop(){
        window.scrollTo(0,0);
    }
    goContenu(){
        console.log('go contenu',this.mainRef.current.offsetTop,this.mainSpacerRef.current.offsetTop);
        setTimeout(()=>window.scrollTo(0,this.mainRef.current.offsetTop+this.mainSpacerRef.current.offsetTop),10);
    }
    goTo(url){
        console.log(url);
        if ( url.indexOf('http://')>=0 || url.indexOf('https://')>=0)
            window.open(url, '_blank');
        else
            this.history.push(url);
    }
    resize(){
        let widthClass='xs';
        if (window.innerWidth>767) widthClass='sm';
        if (window.innerWidth>959) widthClass='md';
        if (window.innerWidth>1200) widthClass='lg';
        let fond={};
        fond.widthClass=widthClass;
        fond.ratio=window.innerWidth/2000;
        if (window.innerWidth<=767) {
            fond.dy=Math.min(0,-window.innerWidth+300);
            fond.dx=0;
        }
        if (window.innerWidth>767) {
            fond.dy=Math.min(0,(-window.innerWidth+767)*0.2);
            fond.dx=0;
        }
        if (window.innerWidth>992) {
            fond.dy=Math.min(0,(-window.innerWidth+992)*0.2);
            fond.dx=0;
        }
        if (window.innerWidth>1200) {
            fond.dy=Math.max(0,(window.innerWidth-1200)*0.08);
            fond.dx=0;
        }
        if (window.innerWidth>1600) {
            fond.dy=-100+Math.min(0,(-window.innerWidth+1600)*0.1);
            fond.dx=0;
        }
        this.setState({screenSize:widthClass,fond:fond});
    }
    render(){
        let appClass='app '+this.state.screenSize;
        if (this.state.wait>5) appClass+=' loaded';
        let router='';
        if (this.state.routes.length>0 && _.find(this.state.routes,{url:'/'}))
            router=<Router
                routes={this.state.routes}
                templates={this.state.templates}
                goTo={this.goTo}
                goTop={this.goTop}
                goContenu={this.goContenu}
                history={this.history}
                playlist={this.playlist}
                modele={this.state.modele}
                methods={this.methods}
                onRouteHasChanged={this.handleRouteHasChanged}
                />;
        let audioPlayer='';
        let audioClass='main-audio-player';
        if (this.state.hidePlayer) audioClass+=' ko';
        if (this.state.modele.selectedPlayer) audioPlayer=<div className={audioClass}>
            {[this.state.modele.selectedPlayer].map((p)=>
                <PlayerAudio
                key={p.url}
                playlist={this.playlist}
                modele={this.state.modele}
                methods={this.methods}
                sound={p}
                small={true}
                />
            )}

        </div>;
        return (
            <div className={appClass}>
                <div id="canvas-container">
                    <Fond progress={this.state.progress} fond={this.state.fond} ref={this.fondRef}/>
                </div>
                <div className="header">
                </div>
                <div className='main' ref={this.mainRef}>
                    <Menu
                    currentRoute={this.state.currentRoute}
                    menu={this.state.modele.menu}
                    goTop={this.goTop}
                    goTo={this.goTo}
                    />
                    <Slider
                    slider={this.state.slider}
                    goTo={this.goTo}
                    onSliderLoaded={this.handleSliderLoaded}
                    />
                    <div id="main-spacer" className="clearfix" ref={this.mainSpacerRef}></div>
                    <div id="main-container" className="second col-xs-120 col-md-110 col-md-offset-5 col-lg-90 col-lg-offset-15">
                    {router}
                    </div>
                    <div className="clearfix"></div>
                    {audioPlayer}
                </div>
            </div>
        );
    }
}

export default App;
