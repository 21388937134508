import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import Html from 'components/Html.js';
import TuileEvenement from 'components/TuileEvenement.js';
import Image from 'components/Image';

class ActionCulturelleAction extends Component {
    render() {
        let data=this.props.data;
        return (
            <div>
            <div className="retour-btn">
                <span onClick={()=>this.props.goTo(data.backUrl)} className="pointer retour-ctrl">
                    <span className="back">&lt;</span> {data.backTitre}
                </span>
            </div>
            <div>
                <Image className="img-responsive" alt={data.titre} src={data.image} width="2000" height="500"/>
            </div>
            <h3 className="titre" dangerouslySetInnerHTML={{__html:data.titre}}></h3>
            <div className="paragraphe2">
                <Html html={data.texte} navigate={this.props.goTo}/>
            </div>
            <div className="row liste">
                {data.evts.map((id,i)=>{
                    let e=_.find(this.props.modele.evts,{id_eve:id});
                    let res=[
                        <div key={e.id_eve} className="col-xs-60 col-sm-40 col-md-30">
                            <TuileEvenement
                            evenement={e}
                            goTo={this.props.goTo}
                            />
                        </div>
                    ];
                    if (i%4===3) res.push(<div key={e.id_eve+'-1'} className="clearfix inter hidden-xs hidden-sm"></div>);
                    if (i%3===2) res.push(<div key={e.id_eve+'-2'} className="clearfix inter visible-sm"></div>);
                    if (i%2===1) res.push(<div key={e.id_eve+'-3'} className="clearfix inter visible-xs"></div>);
                    return res;
                })}
            </div>
            <div className="clearfix"></div>
            </div>
        );
    }
}
export default ActionCulturelleAction;
