import React, { Component } from 'react';
import _ from 'lodash';
import 'components/Evenement.scss';
import 'index.css';
import TuileEvenement from 'components/TuileEvenement.js';
import Html from 'components/Html';

class Evenement extends Component {
    render() {
        let data=this.props.data;
        let ei=_.findIndex(this.props.modele.evts,{id_eve:data.id_eve});
        let e=this.props.modele.evts[ei];
        if (e) {
          let ePrev, eNext, prev, next;
          if (ei>0) ePrev=this.props.modele.evts[ei-1];
          if (ei<this.props.modele.evts.length-1) eNext=this.props.modele.evts[ei+1];
          if (ePrev) prev=<span onClick={()=>this.props.goTo(ePrev.url)} className="pointer prev retour-ctrl">
              Précédent
          </span>;
          else prev=<span className="prev retour-ctrl">
              Précédent
          </span>;
          if (eNext) next=<span onClick={()=>this.props.goTo(eNext.url)} className="pointer next retour-ctrl">
              Suivant
          </span>;
          else next=<span className="next retour-ctrl">
              Suivant
          </span>;
          let groupe='';
          if (e.groupe_url) groupe=<span onClick={()=>this.props.goTo(e.groupe_url)} className="pointer retour-ctrl">
              <span className="back">&gt;</span> {e.groupe_titre}
          </span>;
          let lieu2='';
          if (e.lieu2) lieu2=' / '+e.lieu2;
          let ac='';
          if (e.action_url) ac=<span onClick={()=>this.props.goTo(e.action_url)} className="pointer retour-ctrl">
              <span className="back">&gt;</span> {e.action_titre}
          </span>;
          let descIntervenant='';
          let label=e.label || '';
          let intervenant='';
          e.tags.map((t)=>{
              if (t.indexOf('#intervenant:')!==-1) intervenant=t.replace('#intervenant:','').replace('_','');
              return null;
          });
          if (intervenant !=='') {
              label+=" animé par "+ intervenant;
              descIntervenant=[<br key='0' />,<span key='1'>{label}</span>];
          }
          let pjs='';
          if (e.pjs && e.pjs.length>0) pjs=<div className='pj'>
              <hr />
              <h3 className='evenement-titre small'>À télécharger :</h3>
              {e.pjs.map((pj,i)=>{
                  return (
                      <p key={i}><a href={pj.url}>{pj.titre}</a> <span className='pj-taille'>{pj.taille}</span></p>
                  );
              })}
          </div>;
          return (
              <div className='row'>
                  <div className="col-xs-120 col-sm-40 col-md-30 row">
                      <div className="col-xs-60 col-sm-120">
                          <TuileEvenement
                          evenement={e}
                          goTo={this.props.goTo}
                          />
                      </div>
                      <div className="col-xs-60 col-sm-120">
                          <p className="hidden-xs">&nbsp;</p>
                          {groupe && <p>{groupe}</p>}
                          <p>{ac}</p>
                      </div>
                      <div className="clearfix inter"></div>
                  </div>
                  <div className="detail-date col-xs-120 col-sm-80 col-md-90">
                      <div className="retour-btn">
                          <span onClick={()=>this.props.goTo(data.agenda_url)} className="pointer retour-ctrl">
                              <span className="back">&lt;</span> L'agenda
                          </span>
                          {prev}
                          {next}
                      </div>
                      <strong>
                          {e.titre} {e.dates} - {e.lieu}{lieu2}{descIntervenant}
                      </strong>
                      <h3 className='evenement-titre' dangerouslySetInnerHTML={{__html:e.detail_titre}}></h3>
                      <div className='evenement-texte'>
                          <Html html={e.detail_texte} navigate={this.props.goTo}/>
                          {pjs}
                      </div>
                      <a href={"https://www.facebook.com/sharer/sharer.php?u="+e.url_absolue} target="_blank" rel="noopener noreferrer"><div className="fb">partager sur</div></a>
                  </div>
              </div>
          );
        } else {
          return <div className='row'></div>;
        }
    }
}
export default Evenement;
