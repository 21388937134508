import React, { Component } from 'react';
import 'index.css';
import 'components/TuileBlog.scss';
import Image from 'components/Image';

class TuileBlog extends Component {
    render() {
        return (
            <div className="col-xs-120 col-sm-60 col-md-40" onClick={()=>this.props.goTo(this.props.billet.url)}>
                <div className="tuile pointer">
                    <Image className="img-responsive" alt={this.props.billet.titre} src={this.props.billet.imageTuile} width="600" height="300"/>
                    <div className="blog-table-container">
                    <table>
                        <tbody>
                            <tr>
                                <td dangerouslySetInnerHTML={{__html:this.props.billet.titre_court}}>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div className="blog-date">{this.props.billet.date}</div>
                </div>
            </div>
        );
    }
}
export default TuileBlog;
