import React, { Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import 'index.css';
import 'components/Accueil.scss';
import Html from 'components/Html.js';
import Blog from 'components/Blog.js';
import TuileEvenement from 'components/TuileEvenement.js';

class Accueil extends Component {
    render() {
        let data=this.props.data;
        let d=moment().format('YYYY/MM/DD');
        let evts=_.filter(this.props.modele.evts,(e)=>e.date_fin>d);
        let selection=_.filter(evts,(e,i)=>i<4);
        let prochainement='';
        if (selection.length>0) prochainement=[
            <h3 className="titre" key='1'>Prochainement</h3>,
            <div className="row " key='2'>
                {selection.map((e,i)=>{
                    let res=[
                        <div key={e.id_eve} className="col-xs-60 col-sm-40 col-md-30 fadein">
                            <TuileEvenement
                            evenement={e}
                            goTo={this.props.goTo}
                            />
                        </div>
                    ];
                    if (i%4===3) res.push(<div key={e.id_eve+'-1'} className="clearfix inter hidden-xs hidden-sm"></div>);
                    if (i%3===2) res.push(<div key={e.id_eve+'-2'} className="clearfix inter visible-sm"></div>);
                    if (i%2===1) res.push(<div key={e.id_eve+'-3'} className="clearfix inter visible-xs"></div>);
                    return res;
                })}
                <div className="clearfix"></div>
            </div>,
            <p key='3'>
                <span onClick={()=>this.props.goTo(selection[0].agenda_url)} className="pointer retour-ctrl">
                    <span className="back">&gt;</span> Voir tout l'agenda
                </span>
            </p>
        ];
        return <div>
            <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
            <div className="paragraphe">
                <Html html={data.texte} navigate={this.props.goTo}/>
                <div className="focus row">
                {data.editoFocus.map((e,i)=>{
                    return (
                        <div key={i} className="col-xs-120 col-md-40 focus-item">
                            <div>
                            <Html html={e.texte} navigate={this.props.goTo}/>
                            <div className="focus-link" onClick={()=>this.props.goTo(e.lienUrl)}>{e.lienTexte}</div>
                            </div>
                        </div>
                    );
                })}
                <div className="clearfix"></div>
                </div>
                <Html html={data.texteBas} navigate={this.props.goTo}/>
            </div>
            {prochainement}
            <h3 className="titre">Le Blog</h3>
            <Blog goTo={this.props.goTo} modele={this.props.modele}/>
        </div>;
    }
}
export default Accueil;
