import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Mentions.scss';
import Image from 'components/Image';
import Html from 'components/Html';

class Mentions extends Component {
    render() {
        let data=this.props.data;
        let credits=[];
        data.credits.map((c)=>{
            let cr=_.find(credits,{credits:c.credits});
            if (!cr) credits.push({credits:c.credits,imgs:[c.image]});
            else {
                cr.imgs.push(c.image);
            }
            return null;
        });
        let creditsPhotos ='';
        if (data.credits.length>0) creditsPhotos= [
            <h3 key='1' className='spip'>Crédits photographiques</h3>,
            <div key='2' >
            {credits.map((c,i)=>{
                let imgs=[];
                c.imgs.map((img,i)=>{
                    imgs.push(
                        <div key={i} className='col-xs-60 col-md-40 col-lg-30 pad0'>
                            <Image className="img-responsive" alt="illustration" src={img} width="400" height="200"/>
                            <div className="credits-line">© {c.credits}</div>
                        </div>
                    );
                    return null;
                })
                return imgs;
            })}
            <div className="clearfix"></div>
        </div>];
        return <div>
            <h3 className='titre'>mentions légales</h3>
            <div className="paragraphe">
                <Html html={data.texte} navigate={this.props.goTo}/>
            </div>
            {creditsPhotos}
        </div>;
    }
}
export default Mentions;
