import React, { Component } from 'react';
import 'index.css';
import 'components/Blog.scss';
import TuileBlog from 'components/TuileBlog';

class Blog extends Component {
    render() {
        return (
            <div className="row">
                {this.props.modele.billets.map((billet,i)=>{
                    let res=[<TuileBlog key={billet.url} billet={billet} goTo={this.props.goTo}/>];
                    if (i%3===2) res.push(<div key={billet.id_eve+'-2'} className="clearfix inter visible-md"></div>);
                    if (i%2===1) res.push(<div key={billet.id_eve+'-3'} className="clearfix inter visible-sm"></div>);
                    return res;
                })}
            </div>
        );
    }
}
export default Blog;
