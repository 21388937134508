import React, { Component } from 'react';
import 'index.css';
import Html from 'components/Html';
import Image from 'components/Image';

class ActionCulturelle extends Component {
    render() {
        let data=this.props.data;
        return (
            <div>
            <div className="retour-btn">
                <span onClick={()=>this.props.goTo(data.backUrl)} className="pointer retour-ctrl">
                    <span className="back">&lt;</span> {data.backTitre}
                </span>
            </div>
            <div>
                <Image className="img-responsive" alt={data.titre} rel={data.imageCredits} src={data.image} width="2000" height="500"/>
            </div>
            <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
            <div className="paragraphe2">
                <Html html={data.texte} navigate={this.props.goTo}/>
            </div>
            <div className="row liste">
                {data.acs.map((ac)=>{
                    return (
                        <div className="col-xs-120 col-sm-60 col-md-40" key={ac.url} onClick={()=>this.props.goTo(ac.url)}>
                            <div className="tuile pointer">
                                <Image className="img-responsive" alt={ac.titre} src={ac.image} width="600" height="300"/>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td dangerouslySetInnerHTML={{__html:ac.titre}}>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="clearfix"></div>
            </div>
        );
    }
}
export default ActionCulturelle;
