import React, { Component } from 'react';
import 'index.css';
import 'components/PlayerAudio.scss';
import Html from 'components/Html';
import ProgressBar from 'components/ProgressBar';

class PlayerAudio extends Component {
    constructor(props){
        super(props);
        this.state={
            playing:false,
            position:0,
            currentTime:0,
        };
        this.handleTimeupdate=this.handleTimeupdate.bind(this);
        this.handlePlay=this.handlePlay.bind(this);
        this.handlePause=this.handlePause.bind(this);
        this.player=this.props.playlist.getPlayer(this.props.sound.url);
    }
    componentDidMount(){
        this.setState({playing:!this.player.audio.paused});
        this.props.playlist.ed.on('timeupdate-'+this.player.uuid,this.handleTimeupdate);
        this.props.playlist.ed.on('play-'+this.player.uuid,this.handlePlay);
        this.props.playlist.ed.on('pause-'+this.player.uuid,this.handlePause);
    }
    componentWillUnmount(){
        this.props.playlist.ed.off('timeupdate-'+this.player.uuid,this.handleTimeupdate);
        this.props.playlist.ed.off('play-'+this.player.uuid,this.handlePlay);
        this.props.playlist.ed.off('pause-'+this.player.uuid,this.handlePause);
    }
    handlePlay(){
        this.setState({
            playing:true,
        })
    }
    handlePause(){
        this.setState({
            playing:false,
        })
    }
    handleTimeupdate(e,data){
        this.setState({
            position:data.position,
            currentTime:data.currentTime,
        })
    }
    render() {
        let s=this.props.sound;
        let progress='';
        if (!this.props.xSmall) progress=<ProgressBar
            currentTime={this.state.currentTime}
            position={this.state.position}
            duration={this.player.duration}
            onPositionUpdate={(p)=>this.props.playlist.seek(s.url,p)}
            />;
        let soundClass='son';
        if (this.state.playing) soundClass+=' playing';
        if (this.props.small) soundClass+=' small';
        if (this.props.xSmall) soundClass+=' xSmall';
        soundClass+=' unlocked';
        return (
            <div className={soundClass}>
                <h4>
                    <span className="son-titre"><Html html={s.titre} navigate={this.props.goTo} inline/></span><br className="visible-xs"/>
                    <span className="son-groupe"><Html html={s.groupe} navigate={this.props.goTo} inline/></span>
                </h4>
                <div className="son-controls">
                    <div className="son-titre-small"><Html html={s.titre} navigate={this.props.goTo} inline/></div>
                    <span onClick={()=>this.props.playlist.play(s.url)} className='play'></span>
                    <span onClick={()=>this.props.methods.playNextSound()} className='next'></span>
                    <span onClick={()=>this.props.playlist.pause(s.url)} className='pause'></span>
                    <div className="son-progress">{progress}</div>
                </div>
            </div>
        );
    }
}
export default PlayerAudio;
