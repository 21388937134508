import React, { Component } from 'react';
import 'index.css';
import 'components/Contacts.scss';
import Html from 'components/Html';

class Contacts extends Component {
    render() {
        let data=this.props.data;
        return (
            <div>
            <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
            <div className="paragraphe">
                <Html html={data.texte} navigate={this.props.goTo}/>
                {data.partenaires.length>0 && <div className="partenaires">
                    <h4><b>Pirèna Immatèria reçoit le soutien de :</b></h4>
                    <div>
                    {data.partenaires.map((p)=><div key={p.logo} className="col-xs-40 col-sm-30 col-md-20 col-lg-15">
                        <img alt={p.titre} className="img-responsive" src={p.logo}/>
                        <p>{p.titre}</p>
                    </div>)}
                    <div className="clearfix"></div>
                    </div>
                </div>}
            </div>
            </div>
        );
    }
}
export default Contacts;
