import React, { Component } from 'react';
import 'index.css';
import Html from 'components/Html';

class Collectages extends Component {
    render() {
        let data=this.props.data;
        return (
            <div>
            <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
            <div className="paragraphe">
                <Html html={data.texte} navigate={this.props.goTo}/>
            </div>
            </div>
        );
    }
}
export default Collectages;
