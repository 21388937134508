import React, { Component } from 'react';
import _ from 'lodash';
import 'components/Billet.scss';
import 'index.css';
import Html from 'components/Html.js';
import Image from 'components/Image';
import TuileBlog from 'components/TuileBlog';

class Billet extends Component {
    render() {
        let data=this.props.data;
        let bi=_.findIndex(this.props.modele.billets,{url:data.url});
        let billet=this.props.modele.billets[bi];
        let bPrev, bNext, prev, next;
        if (bi>0) bPrev=this.props.modele.billets[bi-1];
        if (bi<this.props.modele.evts.length-1) bNext=this.props.modele.billets[bi+1];
        if (bPrev) prev=<span onClick={()=>this.props.goTo(bPrev.url)} className="pointer prev retour-ctrl">
            Suivant
        </span>;
        else prev=<span className="prev retour-ctrl">
            Suivant
        </span>;
        if (bNext) next=<span onClick={()=>this.props.goTo(bNext.url)} className="pointer next retour-ctrl">
            Précédent
        </span>;
        else next=<span className="next retour-ctrl">
            Précédent
        </span>;
        let autresTab=_.filter(this.props.modele.billets, (b) => b.url!==data.url );
        let autres='';
        if (autresTab.length>0) autres=[
            <hr key="1"/>,
            <h4 key="2">à lire aussi :</h4>,
            <div key="3" className="row liste">
                {autresTab.map((billet)=>{
                    return (
                        <TuileBlog key={billet.url} billet={billet} goTo={this.props.goTo}/>
                    );
                })}
            </div>
        ];
        let pjs='';
        if (billet.pjs && billet.pjs.length>0) pjs=<div className='pj'>
            <hr />
            <h3 className='billet-titre small'>À télécharger :</h3>
            {billet.pjs.map((pj,i)=>{
                return (
                    <p key={i}><a href={pj.url}>{pj.titre}</a> <span className='pj-taille'>{pj.taille}</span></p>
                );
            })}
        </div>;
        return (
            <div className="billet">
                <h3 className="titre" dangerouslySetInnerHTML={{__html:billet.titre}}></h3>
                <div className="retour-btn">
                {next}
                {prev}
                </div>
                <div className="intro">
                    <Image className="img-responsive" alt={billet.titre} src={billet.image} width="2000" height="500"/>
                    <small className="billet-date">{billet.date}</small>
                </div>
                <div className="paragraphe">
                    <Html html={billet.texte} navigate={this.props.goTo}/>
                    {pjs}
                    <a href={"https://www.facebook.com/sharer/sharer.php?u="+billet.url_absolue} target="_blank" rel="noopener noreferrer"><div className="fb">partager sur</div></a>
                </div>
                {autres}
                <div className="clearfix"></div>
            </div>
        );
    }
}
export default Billet;
