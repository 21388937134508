import parse from 'html-react-parser';
import { reactStringReplace } from 'utils/StringReplace';
import React from 'react';

const Utils={
    shuffle:(a)=>{
        for(let i = a.length - 1; i > 0; i--){
            const j = Math.floor(Math.random() * i)
            const temp = a[i]
            a[i] = a[j]
            a[j] = temp
        }
    },
    dateCompareAsc:(a,b)=>{
        return a.timestamp_fin - b.timestamp_fin;
    },
    dateCompareDesc:(a,b)=>{
        return b.timestamp_fin - a.timestamp_fin;
    },
    dateCompareBlog:(a,b)=>{
        return b.timestamp - a.timestamp;
    },
    formatText:(text,navigate)=>{
      let res=reactStringReplace(text, /(\[([^>\]]+)>>([^\]]+)\])/gm, (matches, i) => {
        return <span className='lien' onClick={()=>navigate(matches[2])} key={i}>{matches[1]}</span>;
      });
      return res;
    },
    parseWithLinks:(text,navigate)=>{
      if (text) {
        let res=text;
        const options = {
          replace: ({type,data}) => {
            if (
              type === 'text'
            ) {
              return <>{Utils.formatText(data,navigate)}</>;
            }
          }
        };
        return parse(res, options);
      }
      return '';
    }
}
export default Utils;
