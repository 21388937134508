import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Polyphonies.scss';
import Html from 'components/Html';
import Image from 'components/Image';
import Sons from 'components/Sons';
import PlayerAudio from 'components/PlayerAudio';

class Polyphonie extends Component {
    constructor(props){
        super(props);
        this.state={
            refresh:0,
            filter:'',
        };
        this.refresh=this.refresh.bind(this);
        this.filter=this.filter.bind(this);
    }
    filter(g){
        this.setState({filter:g});
        this.props.methods.setSoundFilter(g);
    }
    refresh(){
        this.setState({refresh:this.state.refresh+1});
    }
    render() {
        let data=this.props.data;
        let groupes=[];
        this.props.modele.sons.map((s,i)=>{
            if (s.groupe && groupes.indexOf(s.groupe)===-1)
                groupes.push(s.groupe);
            return null;
        });
        let toutClass= this.state.filter ? 'button' : 'button on';
        let sonsFilter='';
        if (groupes.length>0) sonsFilter=<div>
            <p>
            {groupes.map((g,i)=>{
                let btnClass='button';
                if (g===this.state.filter) btnClass+=' on';
                return <span key={i} onClick={()=>this.filter(g)} className={btnClass}>{g}</span>;
            })}
            <span onClick={()=>this.filter('')} className={toutClass}>tout</span>
            </p>
        </div>;
        let selection;
        if (!this.props.modele.soundFilter) selection=this.props.modele.sons;
        else selection=_.filter(this.props.modele.sons,{groupe:this.props.modele.soundFilter});
        let ecouter='';
        if (this.props.modele.sons.length>0) {
            let player=null;
            if (this.props.modele.selectedPlayer) player=this.props.modele.selectedPlayer;
            else if (this.props.modele.sons.length>0) player=this.props.modele.sons[0];
            ecouter=[<h3 key='titre'>
                Écouter
                <PlayerAudio
                key={player.url}
                playlist={this.props.playlist}
                modele={this.props.modele}
                methods={this.props.methods}
                sound={player}
                xSmall={true}
                />
            </h3>,
            <div className="liste" key='liste'>
                {sonsFilter}
                <Sons
                sons={selection}
                playlist={this.props.playlist}
                modele={this.props.modele}
                methods={this.props.methods}
                />
            </div>
            ]
        }
        return (
            <div>
            <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
            <div className="paragraphe">
                <Html html={data.texte} navigate={this.props.goTo}/>
            </div>
            <div className="row liste">
                {this.props.modele.groupes.map((groupe)=>{
                    return (
                        <div className="col-xs-120 col-sm-60 col-md-40" key={groupe.url} onClick={()=>this.props.goTo(groupe.url)}>
                            <div className="tuile pointer groupe">
                                <Image className="img-responsive" alt={groupe.titre} src={groupe.image} width="600" height="300"/>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td dangerouslySetInnerHTML={{__html:groupe.titre}}>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="clearfix"></div>
            {ecouter}
            </div>
        );
    }
}
export default Polyphonie;
