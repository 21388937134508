import React, { Component } from 'react';
import 'index.css';
import Html from 'components/Html.js';
import Image from 'components/Image';

class ActionCulturelleRub extends Component {
    render() {
        let data=this.props.data;
        return (
            <div>
            <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
            <div className="paragraphe2">
                <Html html={data.texte} navigate={this.props.goTo}/>
            </div>
            <div className="row liste">
                {data.actions.map((action)=>{
                    return (
                        <div className="col-xs-120 col-sm-60 col-md-40" key={action.url} onClick={()=>this.props.goTo(action.url)}>
                            <div className="tuile pointer">
                                <Image className="img-responsive" alt={action.titre} src={action.image} width="600" height="300"/>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td dangerouslySetInnerHTML={{__html:action.titre}}>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="clearfix"></div>
            </div>
        );
    }
}
export default ActionCulturelleRub;
